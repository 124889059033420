import React, { createContext, useState, useContext, useEffect } from 'react';
import axios from 'axios';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [authToken, setAuthToken] = useState(null);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      setAuthToken(token);
    }
  }, []);

  const login = async (username, password) => {
    console.log('Попытка входа для пользователя:', username);
    try {
      const url = '/api/login'; // Используем относительный URL благодаря прокси в package.json.
      console.log('Отправка запроса на:', url);
      const response = await axios.post(url, { username, password }, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      console.log('Ответ получен:', response);
      if (response.data && response.data.token) {
        console.log('Токен получен успешно');
        setAuthToken(response.data.token);
        localStorage.setItem('token', response.data.token);
        return true;
      } else {
        console.log('Токен отсутствует в ответе');
        return false;
      }
    } catch (error) {
      console.error('Ошибка входа:', error);
      if (error.response) {
        console.error('Статус ответа:', error.response.status);
        console.error('Данные ответа:', error.response.data);
      } else if (error.request) {
        console.error('Запрос был сделан, но ответ не получен', error.request);
      } else {
        console.error('Ошибка при настройке запроса', error.message);
      }
      return false;
    }
  };

  const logout = () => {
    console.log('Выполнение выхода');
    setAuthToken(null);
    localStorage.removeItem('token');
  };

  return (
    <AuthContext.Provider value={{ authToken, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
