// src/components/Article.js
import React from 'react';
import './Article.css';

const Article = ({ title, date, image, content }) => {
  return (
    <div className="article-container"> {/* Контейнер для статьи */}
      <div className="article"> {/* Обертка для содержимого статьи */}
        <h2 className="article-title">{title}</h2>
        <p className="article-date">{date}</p>
        {image && <img src={image} alt={title} className="article-image" />}
        <div className="article-content">{content}</div> {/* Основное содержимое статьи */}
      </div>
    </div>
  );
};

export default Article;