import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import './Blog.css';

const importArticles = async () => {
  const articles = [];
  const context = require.context('./articles', false, /\.js$/);

  context.keys().forEach((key) => {
    const module = context(key);
    const articleInfo = module.articleInfo;

    if (articleInfo) {
      articles.push({
        id: articleInfo.id,
        title: articleInfo.title || "Без заголовка",
        date: articleInfo.date || "Дата не указана",
        slug: articleInfo.slug || `article-${articleInfo.id}`
      });
    } else {
      console.error(`Статья ${key} не содержит ожидаемых свойств`);
    }
  });

  return articles.sort((a, b) => b.id - a.id);
};

const Blog = () => {
  const [articles, setArticles] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadArticles = async () => {
      try {
        const loadedArticles = await importArticles();
        setArticles(loadedArticles);
      } catch (error) {
        console.error("Ошибка при загрузке статей:", error);
      } finally {
        setLoading(false);
      }
    };

    loadArticles();
  }, []);

  if (loading) {
    return <div>Загрузка...</div>;
  }

  return (
    <>
      <Helmet>
        <title>Блог - 52week.ru, советы, инструменты и мотивация для достижения финансовых целей.</title>
        <meta name="description" content="Читайте интересные статьи в нашем блоге" />
        <meta property="og:title" content="Блог - 52week.ru, советы, инструменты и мотивация для достижения финансовых целей." />
        <meta property="og:description" content="Читайте интересные статьи в нашем блоге" />
        <meta property="og:type" content="52week.ru" />
        <meta property="og:url" content="https://52week.ru/blog" />
      </Helmet>
      <div className="blog-content">
        <div className="blog-container">
          <div className="blog">
            {articles.map((article) => (
              <div key={article.id} className="article-preview">
                <h2 className="article-title">
                  <Link to={`/article/${article.slug}`}>
                    {article.title}
                  </Link>
                </h2>
                <p className="article-date">{article.date}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Blog;
