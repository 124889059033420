// src/components/Feature.js
import React from 'react';
import './Feature.css';

const Feature = ({ title, date, image, content }) => {
  return (
    <div className="feature-container"> {/* Контейнер для статьи */}
      <div className="feature"> {/* Обертка для содержимого статьи */}
        <h2 className="feature-title">{title}</h2>
        <p className="feature-date">{date}</p>
        {image && <img src={image} alt={title} className="feature-image" />}
        <div className="feature-content">{content}</div> {/* Основное содержимое статьи */}
      </div>
    </div>
  );
};

export default Feature;