// src/components/FeaturePage.js
import React, { useEffect, useState } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import './FeaturePage.css';

// Функция для динамического импорта статей
const importFeatures = async () => {
  const features = {};
  const context = require.context('./features', false, /\.js$/);

  context.keys().forEach((key) => {
    const module = context(key);
    const featureComponent = module.default;
    const featureInfo = module.featureInfo;

    if (featureInfo && featureInfo.slug) {
      features[featureInfo.slug] = { component: featureComponent, info: featureInfo };
    } else {
      console.error(`Статья ${key} не содержит ожидаемых свойств или slug`);
    }
  });

  return features;
};

const FeaturePage = () => {
  const { slug } = useParams();
  const [features, setFeatures] = useState({});
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const loadFeatures = async () => {
      const loadedFeatures = await importFeatures();
      setFeatures(loadedFeatures);
      setLoading(false);

      if (!loadedFeatures[slug]) {
        navigate('/', { replace: true });
      }
    };

    loadFeatures();
  }, [slug, navigate]);

  if (loading) {
    return <div>Загрузка...</div>;
  }

  const feature = features[slug];

  if (!feature) {
    return null;
  }

  const { component: FeatureComponent, info } = feature;

  return (
    <div style={{ padding: '20px' }}>
      <nav style={{ marginBottom: '20px' }}>
        <Link to="/">Назад</Link>
      </nav>
      <h1>{info.title}</h1>
      <FeatureComponent />
    </div>
  );
};

export default FeaturePage;