import React, { useEffect, useRef, useState } from 'react';
import './MiniHeader.css';

const MiniHeader = () => {
  const marqueeTexts = [
    'Цель по инфляции: 4%',
    'Инфляция на октябрь 2024: 8,5%',
    'Ключевая ставка с 28.10.2024: 21%',
  ];

  const marqueeRef = useRef(null);
  const containerRef = useRef(null);
  const [containerWidth, setContainerWidth] = useState(0);
  const speed = 80; // Скорость анимации в пикселях в секунду

  useEffect(() => {
    const updateContainerWidth = () => {
      if (containerRef.current) {
        setContainerWidth(containerRef.current.offsetWidth);
      }
    };

    updateContainerWidth();
    window.addEventListener('resize', updateContainerWidth);

    return () => window.removeEventListener('resize', updateContainerWidth);
  }, []);

  useEffect(() => {
    if (marqueeRef.current && containerWidth > 0) {
      const marqueeWidth = marqueeRef.current.offsetWidth;
      let offset = containerWidth;

      const animate = () => {
        if (marqueeRef.current) {
          offset -= speed / 60;
          marqueeRef.current.style.transform = `translateX(${offset}px)`;

          if (offset < -marqueeWidth) {
            offset = containerWidth;
          }
        }

        requestAnimationFrame(animate);
      };

      marqueeRef.current.style.transform = `translateX(${containerWidth}px)`;
      requestAnimationFrame(animate);
    }
  }, [containerWidth, speed]);

  return (
    <div className="miniHeader" ref={containerRef}>
      <div ref={marqueeRef} className="marquee">
        {marqueeTexts.map((text, index) => (
          <span key={index} className="marquee-item">
            {text}
          </span>
        ))}
      </div>
    </div>
  );
};

export default MiniHeader;