// src/components/PayGovPage.js
import React, { useEffect, useState } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import './PayGovPage.css';

// Функция для динамического импорта статей
const importPay = async () => {
  const pays = {};
  const context = require.context('./pay_gov', false, /\.js$/);

  context.keys().forEach((key) => {
    const module = context(key);
    const payComponent = module.default;
    const payInfo = module.payInfo;

    if (payInfo && payInfo.slug) {
      pays[payInfo.slug] = { component: payComponent, info: payInfo };
    } else {
      console.error(`Статья ${key} не содержит ожидаемых свойств или slug`);
    }
  });

  return pays;
};

const PayGovPage = () => {
  const { slug } = useParams();
  const [pays, setPay] = useState({});
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const loadPay = async () => {
      const loadedPay = await importPay();
      setPay(loadedPay);
      setLoading(false);

      if (!loadedPay[slug]) {
        navigate('/pay', { replace: true });
      }
    };

    loadPay();
  }, [slug, navigate]);

  if (loading) {
    return <div>Загрузка...</div>;
  }

  const pay = pays[slug];

  if (!pay) {
    return null;
  }

  const { component: PayComponent, info } = pay;

  return (
    <div style={{ padding: '20px' }}>
      <nav style={{ marginBottom: '20px' }}>
        <Link to="/pay">Назад к списку льгот</Link>
      </nav>
      <PayComponent />
    </div>
  );
};

export default PayGovPage;