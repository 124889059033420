import React from 'react';
import TaxBreak from '../TaxBreak';
import featureImage from './img/a424d115-a2ed-4764-afd8-af698ff07638.jpeg'; // Правильный путь к изображению
import { generateSlug } from '../../utils/slugGenerator';
import { Helmet } from 'react-helmet';

const Tax1 = () => {
  return (
    <TaxBreak
      title={taxInfo.title}
      date={taxInfo.date}
      image={featureImage}
      content={
        <>
        <Helmet>
        <title>{taxInfo.title}</title>
        <meta name="description" content="В России существует ряд налоговых льгот и социальных выплат, доступных физическим лицам..." />
      </Helmet>
          <p>В России существует ряд налоговых льгот и социальных выплат, доступных физическим лицам. Эти меры призваны облегчить финансовое бремя для определенных категорий граждан и стимулировать некоторые виды экономической активности. Рассмотрим основные виды льгот и процесс их получения:</p>

            <h2>Налоговые льготы</h2>

            <h3>Налог на имущество физических лиц</h3>

            <p>Согласно статье 407 Налогового кодекса РФ, от уплаты налога на имущество физических лиц освобождаются следующие категории граждан:</p>

            <ul>
                <li>Пенсионеры</li>
                <li>Предпенсионеры</li>
                <li>Инвалиды I и II групп</li>
                <li>Инвалиды с детства</li>
                <li>Ветераны Великой Отечественной войны и боевых действий</li>
                <li>Военнослужащие</li>
                <li>Владельцы хозяйственных строений площадью до 50 кв.м</li>
            </ul>

            <p>Льгота предоставляется на один объект каждого вида: квартира или комната, жилой дом, гараж или машино-место, хозяйственное строение.</p>

            <h3>Земельный налог</h3>

            <p>Федеральная льгота уменьшает налоговую базу на кадастровую стоимость 600 квадратных метров одного земельного участка. Этой льготой могут воспользоваться:</p>

            <ul>
                <li>Пенсионеры</li>
                <li>Предпенсионеры</li>
                <li>Инвалиды I и II групп</li>
                <li>Инвалиды с детства</li>
                <li>Ветераны Великой Отечественной войны и боевых действий</li>
                <li>Многодетные семьи</li>
            </ul>

            <h3>Налоговый вычет при покупке жилья</h3>

            <p>Физические лица имеют право на налоговый вычет при покупке жилья или погашении ипотечного кредита. Размер вычета составляет до двадцатитысячекратного установленного законом минимального размера оплаты труда.</p>

            <h3>Налоговый вычет при продаже недвижимости</h3>

            <p>При продаже квартир и жилых домов, находящихся в собственности, из налогооблагаемой базы исключается сумма до двадцатитысячекратного установленного законом минимального размера оплаты труда.</p>

            <h3>Особенности предоставления налоговых льгот</h3>

            <ol>
                <li>С 2019 года действует беззаявительный порядок предоставления льгот для некоторых категорий граждан, включая пенсионеров, предпенсионеров и инвалидов.</li>
                <li>Налоговые органы автоматически применяют льготы на основе данных, полученных из Пенсионного фонда России.</li>
                <li>Для получения льготы по выбору объекта налогообложения необходимо подать заявление в налоговую инспекцию.</li>
                <li>Заявление о предоставлении налоговой льготы можно подать через личный кабинет налогоплательщика на сайте ФНС, по почте или лично в налоговой инспекции или МФЦ.</li>
            </ol>

            <h2>Социальные льготы и выплаты</h2>

            <h3>Социальный контракт</h3>

            <p>Социальный контракт - это соглашение между гражданином и органом социальной защиты населения, по которому государство оказывает помощь в трудной жизненной ситуации.</p>

            <p><strong>Процесс получения:</strong></p>

            <ol>
                <li>Подготовка документов: паспорт, справки о доходах, состав семьи.</li>
                <li>Подача заявления в органы соцзащиты.</li>
                <li>Проверка документов (до 30 рабочих дней).</li>
                <li>Прохождение тестирования (для бизнес-проектов).</li>
                <li>Рассмотрение заявки комиссией.</li>
                <li>Получение средств на банковскую карту (в течение месяца после одобрения).</li>
            </ol>

            <h3>Субсидии на оплату ЖКХ</h3>

            <p>Доступны для пенсионеров и других категорий граждан, если расходы на ЖКХ превышают 22% от общего дохода семьи.</p>

            <p><strong>Процесс получения:</strong></p>

            <ol>
                <li>Сбор документов: паспорт, справки о доходах, квитанции об оплате ЖКХ.</li>
                <li>Обращение в отдел социальной защиты населения по месту жительства.</li>
                <li>Предоставление реквизитов банковского счета.</li>
                <li>Рассмотрение заявления и назначение субсидии.</li>
            </ol>

            <h3>Льготы на оплату ЖКХ для пенсионеров</h3>

            <p>Включают 50% скидку на оплату ЖКХ и компенсацию взносов на капитальный ремонт.</p>

            <p><strong>Процесс получения:</strong></p>

            <ol>
                <li>Подготовка документов: паспорт, пенсионное удостоверение, квитанции.</li>
                <li>Обращение в отдел социальной защиты или МФЦ.</li>
                <li>Подача заявления и документов.</li>
                <li>Рассмотрение заявления и назначение льготы.</li>
            </ol>

            <h3>Социальное обслуживание пожилых людей</h3>

            <p>Предоставляется нуждающимся пожилым гражданам.</p>

            <p><strong>Процесс получения:</strong></p>

            <ol>
                <li>Сбор документов: паспорт, медицинская карта, справка о составе семьи.</li>
                <li>Подача заявления в отдел социальной защиты.</li>
                <li>Оценка нуждаемости и состояния здоровья.</li>
                <li>Принятие решения о предоставлении социального обслуживания.</li>
            </ol>

            <h3>Компенсация по уходу за нетрудоспособным гражданином</h3>

            <p>Выплачивается лицам, осуществляющим уход за инвалидами и пожилыми людьми.</p>

            <p><strong>Процесс получения:</strong></p>

            <ol>
                <li>Подготовка документов: паспорт, трудовая книжка, справка об инвалидности подопечного.</li>
                <li>Обращение в отделение Пенсионного фонда.</li>
                <li>Подача заявления и документов.</li>
                <li>Рассмотрение заявления и назначение компенсации.</li>
            </ol>

            <h3>Pенсия по инвалидности</h3>

            <p>Назначается с даты установления инвалидности.</p>

            <p><strong>Процесс получения:</strong></p>

            <ol>
                <li>Получение справки МСЭ о группе инвалидности.</li>
                <li>Подача заявления в Пенсионный фонд (лично, через МФЦ или онлайн).</li>
                <li>Рассмотрение заявления и назначение пенсии.</li>
            </ol>

            <p>Для получения подробной информации о любой льготе рекомендуется обратиться в местное отделение социальной защиты населения или МФЦ, где предоставят консультацию и помогут с оформлением необходимых документов.</p>
        </>
      }
    />
  );
};

// Заголовок статьи
const title = "Налоговые льготы и социальные выплаты для физических лиц в России: полный обзор";

// Экспортируем объект с информацией о статье
export const taxInfo = {
  id: 1,
  title: title,
  date: "26 ноября, 2024",
  slug: generateSlug(title) // Генерация slug из title
};

export default Tax1;