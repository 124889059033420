// src/components/Pay.js
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import './Pay.css';

// Функция для динамического импорта платежей
const importPays = async () => {
  const pays = [];
  const context = require.context('./pay_gov', false, /\.js$/);

  context.keys().forEach((key) => {
    const module = context(key);
    const payInfo = module.payInfo;

    if (payInfo && payInfo.slug) {
      pays.push({
        id: payInfo.id,
        title: payInfo.title || "Без заголовка",
        date: payInfo.date || "Дата не указана",
        slug: payInfo.slug
      });
    } else {
      console.error(`Платеж ${key} не содержит ожидаемых свойств или slug`);
    }
  });

  // Сортировка платежей по id (чем выше id, тем выше платеж в списке)
  return pays.sort((a, b) => b.id - a.id);
};

const Pay = () => {
  const [pays, setPays] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadPays = async () => {
      try {
        const loadedPays = await importPays();
        setPays(loadedPays);
      } catch (error) {
        console.error("Ошибка при загрузке платежей:", error);
      } finally {
        setLoading(false);
      }
    };

    loadPays();
  }, []);

  if (loading) {
    return <div>Загрузка...</div>;
  }

  return (
    <>
    <Helmet>
        <title>Выплаты от государства - 52week.ru, советы, инструменты и инструкции для получения выплат от государства.</title>
        <meta name="description" content="Читайте советы, инструменты и инструкции для получения выплат от государства" />
        <meta property="og:title" content="Выплаты от государства - 52week.ru, советы, инструменты и инструкции для получения выплат от государства." />
        <meta property="og:description" content="Читайте советы, инструменты и инструкции для получения выплат от государства" />
        <meta property="og:type" content="52week.ru" />
        <meta property="og:url" content="https://52week.ru/pay" />
    </Helmet>
    <div className="pay-content">
      <div className="pay-container">
        <div className="pay">
          {pays.map((pay) => (
            <div key={pay.id} className="pay-preview">
              <h2 className="pay-title">
                <Link to={`/pay/${pay.slug}`}>
                  {pay.title}
                </Link>
              </h2>
              <p className="pay-date">{pay.date}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
    </>
  );
};

export default Pay;