// src/components/PayGov.js
import React from 'react';
import './PayGov.css';

const PayGov = ({ title, date, image, content }) => {
  return (
    <div className="pay-container"> {/* Контейнер для статьи */}
      <div className="pay"> {/* Обертка для содержимого статьи */}
        <h2 className="pay-title">{title}</h2>
        <p className="pay-date">{date}</p>
        {image && <img src={image} alt={title} className="pay-image" />}
        <div className="pay-content">{content}</div> {/* Основное содержимое статьи */}
      </div>
    </div>
  );
};

export default PayGov;