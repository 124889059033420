/* global ym */

import React, { useEffect, useRef, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import flatpickr from 'flatpickr';
import { Russian } from 'flatpickr/dist/l10n/ru.js';
import { Helmet } from 'react-helmet';
import 'flatpickr/dist/flatpickr.min.css';
import './Page2.css';
import { logButtonClick } from '../log';

const Page2 = () => {
  const location = useLocation();
  const dateInputRef = useRef(null);
  const [selectedDate, setSelectedDate] = useState('');

  useEffect(() => {
    if (dateInputRef.current) {
      flatpickr(dateInputRef.current, {
        dateFormat: 'd.m.Y',
        locale: Russian,
        minDate: 'today',
        disableMobile: true,
        onChange: (selectedDates) => {
          setSelectedDate(selectedDates[0] ? selectedDates[0].toLocaleDateString('ru-RU') : '');
        },
      });
    }
  }, []);

  const getCurrentStep = () => {
    switch (location.pathname) {
      case '/1': return 1;
      case '/2': return 2;
      case '/3': return 3;
      case '/4': return 4;
      case '/5': return 5;
      default: return 1;
    }
  };

  const currentStep = getCurrentStep();

  const handleNextClick = (event) => {
    event.preventDefault();

    if (!selectedDate) {
      alert('Пожалуйста, выберите дату перед продолжением!');
    } else {
      // Сохраняем выбранную дату в localStorage
      localStorage.setItem('step2Date', selectedDate);
      
      logButtonClick(event, 'step2');
      ym(97837559,'reachGoal','step_2')
      window.location.href = '/3'; // Можно использовать navigate из react-router-dom
    }
  };

  return (
    <>
    <Helmet>
        <title>К какой дате хочется достичь цель?</title>
        <meta name="description" content="52week.ru - это новый опыт достижения финансовых целей. Советы, инструменты и мотивация для достижения финансовых целей." />
    </Helmet>
    <div className="page2-wrapper">
      <div className="steps-container">
        {[1, 2, 3, 4, 5].map((step) => (
          <div key={step} className={`step-circle ${currentStep === step ? 'active' : ''}`}>
            {step}
          </div>
        ))}
      </div>
      <h2 className="page2-title">К какой дате хочется достичь цель?</h2>
      <div className="page2-container">
        <input
          type="text"
          placeholder="Выберите дату"
          className="input-field"
          ref={dateInputRef}
          readOnly
        />
        <Link to="/3" className="cta-button" onClick={handleNextClick}>
          Дальше
        </Link>
      </div>
    </div>
    </>
  );
};

export default Page2;