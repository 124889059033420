import React from 'react';
import PayGov from '../PayGov';
import featureImage from './img/90f6e3c3-dbbf-43c9-954a-ebd8b94f1303.jpeg'; // Правильный путь к изображению
import { generateSlug } from '../../utils/slugGenerator';
import { Helmet } from 'react-helmet';

const Pay1 = () => {
  return (
    <PayGov
      title={payInfo.title}
      date={payInfo.date}
      image= {featureImage}
      content={
        <>
        <Helmet>
        <title>{payInfo.title}</title>
        <meta name="description" content="В 2024 году российские граждане могут рассчитывать на широкий спектр мер государственной поддержки..." />
      </Helmet>
          <p>В 2024 году российские граждане могут рассчитывать на широкий спектр мер государственной поддержки. Рассмотрим основные льготы и выплаты, а также способы их получения.</p>

            <h2>Поддержка семей с детьми</h2>

            <h3>Единое пособие на ребенка</h3>
            <p>С 1 января 2023 года введено единое пособие для семей с детьми до 17 лет и беременных женщин, объединившее ряд ранее существовавших выплат.</p>
            <p><strong>Как получить:</strong></p>
            <ol>
                <li>Подать заявление через портал Госуслуг или в отделении социальной защиты.</li>
                <li>Предоставить сведения о доходах семьи.</li>
            </ol>

            <h3>Выплаты на первого и второго ребенка</h3>
            <p>Действует автоматическое продление выплат на первого и второго ребенка до трех лет для семей, чей среднедушевой доход не превышает двух прожиточных минимумов.</p>

            <h3>Пособие по уходу за ребенком до полутора лет</h3>
            <p>Единый размер выплаты для безработных составляет 6752 рубля на каждого ребенка, независимо от очередности рождения.</p>

            <h2>Материнский капитал</h2>
            <p>Размер выплат в 2024 году:</p>
            <ul>
                <li>На первого ребенка: 466 617 рублей</li>
                <li>На второго ребенка: дополнительно 150 000 рублей</li>
            </ul>
            <p>Средства можно использовать на приобретение жилья, погашение ипотеки, оплату образования и другие предусмотренные законом цели.</p>

            <h2>Налоговые льготы</h2>

            <h3>Для предпенсионеров</h3>
            <p>Лица предпенсионного возраста могут получать те же налоговые льготы, что и пенсионеры.</p>

            <h3>Для многодетных семей</h3>
            <p>Семьи с тремя и более несовершеннолетними детьми имеют право на дополнительные вычеты по налогу на имущество и земельному налогу.</p>

            <h2>Погашение ипотеки для многодетных семей</h2>
            <p>Программа предусматривает выплату до 450 000 рублей в дополнение к материнскому капиталу.</p>

            <h2>Увеличенные выплаты по больничным</h2>
            <p>До 31 декабря 2024 года действует мера поддержки для граждан с невысокой зарплатой или небольшим стажем работы, гарантирующая выплату не ниже МРОТ.</p>

            <h2>Льготы для инвалидов</h2>
            <p>Инвалиды имеют право на:</p>
            <ul>
                <li>Ежемесячную денежную выплату (ЕДВ)</li>
                <li>Набор социальных услуг (НСУ)</li>
                <li>Льготы по оплате ЖКХ</li>
                <li>Бесплатные лекарства и медицинские изделия</li>
            </ul>

            <h2>Выплаты ветеранам боевых действий</h2>
            <p>Ветераны боевых действий могут рассчитывать на:</p>
            <ul>
                <li>Ежемесячную денежную выплату</li>
                <li>Компенсацию расходов на оплату жилых помещений</li>
                <li>Льготы по пенсионному обеспечению</li>
            </ul>

            <h2>Социальная доплата к пенсии</h2>
            <p>Неработающие пенсионеры, чей доход ниже прожиточного минимума в регионе, имеют право на социальную доплату к пенсии.</p>

            <h2>Льготы для граждан предпенсионного возраста</h2>
            <p>Лица предпенсионного возраста имеют право на:</p>
            <ul>
                <li>Два дня в год на бесплатную диспансеризацию с сохранением зарплаты</li>
                <li>Повышенное пособие по безработице</li>
                <li>Налоговые льготы</li>
            </ul>

            <h2>Индексация социальных выплат</h2>
            <p>С 1 февраля 2024 года планируется индексация социальных выплат на 7,42%, что коснется около 20 миллионов получателей различных пособий и льгот.</p>

            <h2>Повышение пособий по временной нетрудоспособности</h2>
            <p>Пособия по временной нетрудоспособности и по беременности и родам были повышены на 48%, а к следующему году ожидается их повышение в два раза.</p>

            <h2>Автоматическое назначение выплат</h2>
            <p>Большинство социальных гарантий и льгот теперь назначаются в проактивном режиме, без необходимости подачи заявлений. Однако для некоторых пособий, назначаемых по критериям нуждаемости, может потребоваться периодическое подтверждение права на их получение.</p>

            <p><em>Важно отметить, что конкретный набор льгот и выплат может варьироваться в зависимости от региона проживания и индивидуальных обстоятельств. Рекомендуется уточнять актуальную информацию в местных органах социальной защиты или на официальных государственных порталах.</em></p>

            <p><strong>Для получения большинства льгот и выплат необходимо иметь подтвержденную учетную запись на портале Госуслуг. Это позволит вам подавать заявления и получать уведомления о начислениях в электронном виде, что значительно упростит процесс получения государственной поддержки.</strong></p>
                    </>
      }
    />
  );
};

// Заголовок статьи
const title = 'Полный гид по льготам и выплатам для граждан РФ в 2024 году';

// Экспортируем объект с информацией о статье
export const payInfo = {
  id: 1,
  title: title,
  date: "05 марта, 2024",
  slug: generateSlug(title) // Генерация slug из title
};

export default Pay1;