// log.js

// Логирование кнопки на index
export async function logButtonClick(event, buttonName) {
    // Сохраняем URL для перехода
    const targetUrl = event.currentTarget.getAttribute('href');

    const currentDate = new Date().toISOString().split('T')[0];
    const pageUrl = window.location.href;
    const userAgent = navigator.userAgent;

    // Получаем информацию об устройстве, ОС и браузере
    const deviceInfo = getDeviceInfo(userAgent);
    const osInfo = getOSInfo(userAgent);
    const browserInfo = getBrowserInfo(userAgent);

    let ipAddress = 'unknown'; // Значение по умолчанию для IP-адреса

    try {
        // Получаем IP-адрес пользователя
        const response = await fetch('https://api.ipify.org?format=json');
        if (!response.ok) {
            throw new Error(`Failed to fetch IP: ${response.status}`);
        }
        const data = await response.json();
        ipAddress = data.ip; // Устанавливаем IP-адрес, если запрос успешен
    } catch (error) {
        console.error('Error fetching IP:', error);
        // В случае ошибки получения IP, оставляем ipAddress как 'unknown'
    }

    // Формируем запись лога
    const logEntry = `${currentDate}, ${pageUrl}, user123, ${buttonName}, ${deviceInfo}, ${osInfo}, ${browserInfo}, ${ipAddress}`;

    try {
        // Отправка данных на сервер для логирования
        await sendLogEntry(logEntry);
        console.log('Logging successful');
        
        // Переход по ссылке после логирования
        if (targetUrl) {
            window.location.href = targetUrl; 
        }
        
    } catch (error) {
        console.error('Error logging:', error);
        
        // В случае ошибки логирования записываем логи с известными данными
        const fallbackLogEntry = `${currentDate}, ${pageUrl}, user123, ${buttonName}, ${deviceInfo}, ${osInfo}, ${browserInfo}, unknown`;
        try {
            await sendLogEntry(fallbackLogEntry); // Пытаемся отправить данные с неизвестным IP
            console.log('Logging successful with unknown IP');
            
            if (targetUrl) {
                window.location.href = targetUrl; 
            }
            
        } catch (fallbackError) {
            console.error('Fallback logging failed:', fallbackError);
            // Здесь можно добавить дополнительную логику обработки ошибки
        }
    }
}

// Генерация уникального идентификатора пользователя
export async function generateUserId() {
    const currentDate = new Date();
    const logDateTime = `${currentDate.getFullYear()}-${String(currentDate.getMonth() + 1).padStart(2, '0')}-${String(currentDate.getDate()).padStart(2, '0')} ${String(currentDate.getHours()).padStart(2, '0')}:${String(currentDate.getMinutes()).padStart(2, '0')}:${String(currentDate.getSeconds()).padStart(2, '0')}`;

    const userAgent = navigator.userAgent;
    const deviceInfo = getDeviceInfo(userAgent);
    const osInfo = getOSInfo(userAgent);
    const browserInfo = getBrowserInfo(userAgent);

    let ipAddress = 'unknown';
    try {
        const response = await fetch('https://api.ipify.org?format=json');
        if (response.ok) {
            const data = await response.json();
            ipAddress = data.ip;
        }
    } catch (error) {
        console.error('Error fetching IP:', error);
    }

    return `${logDateTime}_${deviceInfo}_${osInfo}_${browserInfo}_${ipAddress}`;
}

async function sendLogEntry(logEntry) {
    return fetch(`${window.location.origin}/log`, { // Используем динамический URL для вашего сервера
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ logEntry }),
    })
    .then(response => {
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
    });
}

// Экспортируем вспомогательные функции
export function getDeviceInfo(userAgent) {
    if (/mobile/i.test(userAgent)) return 'Mobile';
    if (/tablet/i.test(userAgent)) return 'Tablet';
    return 'Desktop';
}

export function getOSInfo(userAgent) {
    if (/windows/i.test(userAgent)) return 'Windows';
    if (/mac/i.test(userAgent)) return 'MacOS';
    if (/linux/i.test(userAgent)) return 'Linux';
    if (/android/i.test(userAgent)) return 'Android';
    if (/ios/i.test(userAgent)) return 'iOS';
    return 'Unknown OS';
}

export function getBrowserInfo(userAgent) {
    if (/chrome/i.test(userAgent)) return 'Chrome';
    if (/firefox/i.test(userAgent)) return 'Firefox';
    if (/safari/i.test(userAgent)) return 'Safari';
    if (/opera|opr/i.test(userAgent)) return 'Opera';
    if (/edge/i.test(userAgent)) return 'Edge';
    if (/trident|msie/i.test(userAgent)) return 'Internet Explorer';
    return 'Unknown Browser';
}