import React, { useState } from 'react';
import { useAuth } from '../context/AuthContext';
import Blog from './Blog'; // Импортируем компонент Blog
import './AdminPage2.css'; // Импортируем стили

const AdminPage2 = () => {
  const { logout } = useAuth();
  const [activeSection, setActiveSection] = useState(null);
  const [newArticle, setNewArticle] = useState({
    title: '',
    image: null,
    content: ''
  });
  const [articles, setArticles] = useState([]);
  const [isAddingArticle, setIsAddingArticle] = useState(false); // Состояние для управления формой
  const [errorMessage, setErrorMessage] = useState(''); // Состояние для сообщения об ошибке

  const handleAddArticle = async () => {
    if (!newArticle.title || !newArticle.content) {
      setErrorMessage('Заголовок и содержание обязательны.');
      return;
    }

    const formData = new FormData();
    formData.append('title', newArticle.title);
    formData.append('content', newArticle.content);
    if (newArticle.image) {
      formData.append('image', newArticle.image);
    }

    try {
      const response = await fetch('/api/articles', {
        method: 'POST',
        body: formData,
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}` // Добавьте токен авторизации
        }
      });

      if (!response.ok) {
        const errorResponse = await response.json();
        throw new Error(errorResponse.error || 'Ошибка при добавлении статьи');
      }

      const result = await response.json();
      console.log(result); // Выводим результат в консоль для отладки

      // Добавляем новую статью в список статей
      setArticles([...articles, { id: Date.now(), title: newArticle.title, date: new Date().toISOString().split('T')[0], slug: `article-${Date.now()}`, content: newArticle.content }]);
      
      // Сбрасываем форму
      setNewArticle({ title: '', image: null, content: '' });
      setIsAddingArticle(false); // Закрываем форму
      setErrorMessage(''); // Очищаем сообщение об ошибке

    } catch (error) {
      console.error('Ошибка:', error);
      setErrorMessage(error.message);
    }
  };

  return (
    <div className="admin-page-body">
      <h1 className="admin-page-title">Панель администратора 2</h1>
      <p className="admin-page-sub-title">Добро пожаловать в защищенную зону!</p>
      <button className="admin-page-cta-button" onClick={logout}>Выйти</button>

      <div className="admin-sections">
        <div className="admin-section" onClick={() => setActiveSection('governmentPayments')}>
          Выплаты от государства
        </div>
        <div className="admin-section" onClick={() => setActiveSection('taxBenefits')}>
          Налоговые льготы
        </div>
        <div className="admin-section" onClick={() => setActiveSection('blog')}>
          Блог
        </div>
      </div>

      {activeSection === 'blog' && (
        <div className="blog-section">
          <button onClick={() => setIsAddingArticle(!isAddingArticle)}>
            {isAddingArticle ? 'Скрыть форму' : 'Добавить новую запись'}
          </button>

          {isAddingArticle && (
            <div className="add-article-form">
              {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
              <input 
                type="text" 
                placeholder="Заголовок статьи" 
                value={newArticle.title} 
                onChange={(e) => setNewArticle({ ...newArticle, title: e.target.value })} 
              />
              <input 
                type="file" 
                accept="image/*" 
                onChange={(e) => setNewArticle({ ...newArticle, image: e.target.files[0] })} 
              />
              <textarea 
                placeholder="Текст статьи в HTML" 
                value={newArticle.content} 
                onChange={(e) => setNewArticle({ ...newArticle, content: e.target.value })} 
              />
              <button onClick={handleAddArticle}>Добавить</button>
            </div>
          )}

          {/* Отображение списка статей */}
          <Blog articles={articles} />
        </div>
      )}
    </div>
  );
};

export default AdminPage2;
