// src/components/TaxPage.js
import React, { useEffect, useState } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import './TaxPage.css';

// Функция для динамического импорта налоговых льгот
const importTaxes = async () => {
  const taxes = {};
  const context = require.context('./tax_break', false, /\.js$/);

  context.keys().forEach((key) => {
    const module = context(key);
    const taxComponent = module.default;
    const taxInfo = module.taxInfo;

    if (taxInfo && taxInfo.slug) {
      taxes[taxInfo.slug] = { component: taxComponent, info: taxInfo };
    } else {
      console.error(`Налоговая льгота ${key} не содержит ожидаемых свойств или slug`);
    }
  });

  return taxes;
};

const TaxPage = () => {
  const { slug } = useParams();
  const [taxes, setTaxes] = useState({});
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const loadTaxes = async () => {
      const loadedTaxes = await importTaxes();
      setTaxes(loadedTaxes);
      setLoading(false);

      if (!loadedTaxes[slug]) {
        navigate('/tax', { replace: true });
      }
    };

    loadTaxes();
  }, [slug, navigate]);

  if (loading) {
    return <div>Загрузка...</div>;
  }

  const tax = taxes[slug];

  if (!tax) {
    return null;
  }

  const { component: TaxComponent, info } = tax;

  return (
    <div style={{ padding: '20px' }}>
      <nav style={{ marginBottom: '20px' }}>
        <Link to="/tax">Назад к списку льгот</Link>
      </nav>
      <TaxComponent />
    </div>
  );
};

export default TaxPage;