import React, { useEffect, useState } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import './ArticlePage.css';

const importArticles = async () => {
  const articles = {};
  const context = require.context('./articles', false, /\.js$/);

  context.keys().forEach((key) => {
    const module = context(key);
    const articleComponent = module.default;
    const articleInfo = module.articleInfo;

    if (articleInfo) {
      articles[articleInfo.slug] = { component: articleComponent, info: articleInfo };
    } else {
      console.error(`Статья ${key} не содержит ожидаемых свойств`);
    }
  });

  return articles;
};

const ArticlePage = () => {
  const { slug } = useParams();
  const [articles, setArticles] = useState({});
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const loadArticles = async () => {
      const loadedArticles = await importArticles();
      setArticles(loadedArticles);
      setLoading(false);

      if (!loadedArticles[slug]) {
        navigate('/blog', { replace: true });
      }
    };

    loadArticles();
  }, [slug, navigate]);

  if (loading) {
    return <div>Загрузка...</div>;
  }

  const article = articles[slug];

  if (!article) {
    return null;
  }

  const { component: ArticleComponent, info } = article;

  return (
    <div style={{ padding: '20px' }}>
      <nav style={{ marginBottom: '20px' }}>
        <Link to="/blog">Назад к блогу</Link>
      </nav>
      <ArticleComponent />
    </div>
  );
};

export default ArticlePage;