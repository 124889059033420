
import React from 'react';
import './TaxBreak.css';

const TaxBreak = ({ title, date, image, content }) => {
  return (
    <div className="tax-container"> {/* Контейнер для статьи */}
      <div className="tax"> {/* Обертка для содержимого статьи */}
        <h2 className="tax-title">{title}</h2>
        <p className="tax-date">{date}</p>
        {image && <img src={image} alt={title} className="tax-image" />}
        <div className="tax-content">{content}</div> {/* Основное содержимое статьи */}
      </div>
    </div>
  );
};

export default TaxBreak;