import React, { useState, useEffect } from 'react';
import './FeedbackPopup.css';
import { logButtonClick, getDeviceInfo, getOSInfo, getBrowserInfo } from '../log';

const FeedbackPopup = ({ onClose }) => {
  const [feedback, setFeedback] = useState('');
  const [rating, setRating] = useState(null);
  const [message, setMessage] = useState('');
  const [userId, setUserId] = useState('');
  const [isLoading, setIsLoading] = useState(false); // Индикатор загрузки

  useEffect(() => {
    const generateUserId = async () => {
      const currentDate = new Date();
      const logDateTime = `${currentDate.getFullYear()}-${String(currentDate.getMonth() + 1).padStart(2, '0')}-${String(currentDate.getDate()).padStart(2, '0')} ${String(currentDate.getHours()).padStart(2, '0')}:${String(currentDate.getMinutes()).padStart(2, '0')}:${String(currentDate.getSeconds()).padStart(2, '0')}`;
      
      const userAgent = navigator.userAgent;
      const deviceInfo = getDeviceInfo(userAgent);
      const osInfo = getOSInfo(userAgent);
      const browserInfo = getBrowserInfo(userAgent);

      let ipAddress = 'unknown';
      try {
        const response = await fetch('https://api.ipify.org?format=json');
        if (response.ok) {
          const data = await response.json();
          ipAddress = data.ip;
        }
      } catch (error) {
        console.error('Error fetching IP:', error);
      }

      const generatedUserId = `${logDateTime}_${deviceInfo}_${osInfo}_${browserInfo}_${ipAddress}`;
      setUserId(generatedUserId);
    };

    generateUserId();
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    logButtonClick(event, 'feedbackPopUpSend');

    if (!feedback.trim()) {
      setMessage("Пожалуйста, введите ваш отзыв.");
      return;
    }

    if (rating === null) {
      setMessage("Пожалуйста, выберите рейтинг.");
      return;
    }

    setIsLoading(true); // Начинаем загрузку
    const reviewData = {
      feedback: feedback || '',
      rating: rating || 0,
      userId: userId || 'unknown',
    };

    try {
      const response = await fetch(`${window.location.protocol}//${window.location.host}/save-review`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(reviewData),
      });

      if (!response.ok) {
        throw new Error(`Ошибка при сохранении отзыва: ${response.statusText}`);
      }

      setMessage("Спасибо за ваше время и оценку");
      
      // Закрываем попап через 3 секунды
      setTimeout(() => {
        onClose(); // Закрываем попап
        resetForm(); // Сбрасываем форму после закрытия
      }, 3000);
      
    } catch (error) {
      console.error(error);
      setMessage("Произошла ошибка при отправке отзыва");
    } finally {
      setIsLoading(false); // Завершаем загрузку
    }
  };

  const resetForm = () => {
    setFeedback('');
    setRating(null);
    setMessage('');
  };

  return (
    <div className="feedback-popup">
      <span className="close-icon" onClick={(event) => { 
        logButtonClick(event, 'feedback_button'); 
        onClose(); // Закрываем попап при нажатии на крестик
      }}>✖</span>
      
      <h2>Обратная связь и предложения</h2>
      
      <textarea
        placeholder="Ваш отзыв..."
        value={feedback}
        onChange={(e) => setFeedback(e.target.value)}
        disabled={isLoading} // Отключаем поле во время загрузки
      />
      
      <div>
        <p>Как вы оцениваете сайт?</p>
        <div className="rating">
          {[1, 2, 3, 4, 5].map((num) => (
            <span
              key={num}
              className={`circle ${rating === num ? 'selected' : ''}`}
              onClick={() => !isLoading && setRating(num)} // Отключаем выбор рейтинга во время загрузки
            >
              {num}
            </span>
          ))}
        </div>
      </div>
      
      <button onClick={handleSubmit} disabled={isLoading}>Отправить</button> {/* Отключаем кнопку во время загрузки */}
      
      {isLoading && <p className="loading-message">Отправка отзыва...</p>} {/* Индикатор загрузки */}
      
      {message && (
        <p className="feedback-message">{message}</p>
      )}
    </div>
  );
};

export default FeedbackPopup;