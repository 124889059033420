import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './FutureProduct.css';

const FutureProduct = () => {
  // Функция для динамического импорта фич
  const importFeatures = async () => {
    const features = [];
    const context = require.context('./features', false, /\.js$/);

    context.keys().forEach((key) => {
      const module = context(key);
      const featureInfo = module.featureInfo;

      if (featureInfo && featureInfo.slug) {
        features.push({
          id: featureInfo.id,
          title: featureInfo.title || "Без заголовка",
          date: featureInfo.date || "Дата не указана",
          slug: featureInfo.slug
        });
      } else {
        console.error(`Фича ${key} не содержит ожидаемых свойств или slug`);
      }
    });

    // Сортировка фич по id (чем выше id, тем выше фича в списке)
    return features.sort((a, b) => b.id - a.id);
  };

  const [features, setFeatures] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadFeatures = async () => {
      try {
        const loadedFeatures = await importFeatures();
        setFeatures(loadedFeatures);
      } catch (error) {
        console.error("Ошибка при загрузке фич:", error);
      } finally {
        setLoading(false);
      }
    };

    loadFeatures();
  }, []);

  if (loading) {
    return <div>Загрузка...</div>;
  }

  return (
    <div className="future-block">
      <h2 className="future-title">Скоро в продукте</h2>
      <div className="future-list">
        {features.map((feature) => (
          <Link key={feature.id} to={`/feature/${feature.slug}`} className="future-item">
            - {feature.title}
          </Link>
        ))}
      </div>
    </div>
  );
};

export default FutureProduct;