import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import './News.css'; // Импортируем стили

const News = () => {


    // Функция для динамического импорта статей
    const importArticles = async () => {
        const articles = [];
        const context = require.context('./articles', false, /\.js$/); // Импортируем все .js файлы из папки articles
    
        context.keys().forEach((key) => {
        const module = context(key);
        const articleInfo = module.articleInfo; // Получаем информацию о статье
    
        if (articleInfo) {
            articles.push({
            id: articleInfo.id,
            title: articleInfo.title || "Без заголовка",
            date: articleInfo.date || "Дата не указана",
            });
        } else {
            console.error(`Статья ${key} не содержит ожидаемых свойств`);
        }
        });
    
        // Сортировка статей по id (чем выше id, тем выше статья)
        return articles.sort((a, b) => b.id - a.id);
    };

    const [articles, setArticles] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const loadArticles = async () => {
        try {
            const loadedArticles = await importArticles();
            setArticles(loadedArticles);
        } catch (error) {
            console.error("Ошибка при загрузке статей:", error);
        } finally {
            setLoading(false);
        }
        };

        loadArticles();
    }, []);

  return (
    <div className="news-block">
      <h2 className="news-title">Новости</h2>
      <div className="news-list">

        {articles.map((article) => (

                <Link to={`/article/${article.id}`} className="news-item">
                  - {article.title}
                </Link>
            
          ))}

      </div>
    </div>
  );
};

export default News;