import React, { useEffect, useRef, useState } from 'react';
import './DepositRateBlock.css';

const DepositRateBlock = ({ rate1, rate2, linkText, linkUrl }) => {
  const blockWrapperRef = useRef(null);
  const [fontSize, setFontSize] = useState(2.2); // Начальный размер шрифта в vh

  // Функция для автоматического изменения размера шрифта
  const adjustFontSize = () => {
    if (blockWrapperRef.current) {
      const containerHeight = blockWrapperRef.current.clientHeight;
      let newFontSize = fontSize;

      // Устанавливаем начальный размер шрифта
      blockWrapperRef.current.style.fontSize = `${newFontSize}vh`;

      // Проверяем переполнение и уменьшаем размер шрифта при необходимости
      while (blockWrapperRef.current.scrollHeight > containerHeight && newFontSize > 1) {
        newFontSize -= 0.1; // Уменьшаем размер шрифта
        blockWrapperRef.current.style.fontSize = `${newFontSize}vh`;
      }

      setFontSize(newFontSize); // Обновляем состояние с новым размером шрифта
    }
  };

  useEffect(() => {
    adjustFontSize(); // Вызываем функцию при изменении данных
  }, [rate1, rate2]);

  useEffect(() => {
    adjustFontSize(); // Вызываем функцию при изменении высоты окна
    window.addEventListener('resize', adjustFontSize); // Добавляем обработчик события изменения размера окна

    return () => {
      window.removeEventListener('resize', adjustFontSize); // Убираем обработчик при размонтировании
    };
  }, []);

  return (
    <div className="blockWrapper" ref={blockWrapperRef}>
      <h3 className="title">
        Динамика по вкладам
      </h3>
      <div className="rateContainer">
        <span className="rateLabel">Изменение с прошлого месяца:</span>
        <span 
          className={`rateValue ${rate1 >= 0 ? "ratePositive" : "rateNegative"}`}
        >
          {rate1 >= 0 ? '+' : ''}{rate1}%
        </span>
      </div>
      <div className="rateContainer">
        <span className="rateLabel">Максимальная ставка по вкладу:</span>
        <span className="rateValue ratePositive">
          {Math.abs(rate2)}%
        </span>
      </div>
      <div className="bankLinkContainer">
        <span className="bankLabel">Банк:</span>
        <a 
          href={linkUrl} 
          className="link"
          target="_blank"
          rel="noopener noreferrer" // Безопасность при открытии ссылки
        >
          {linkText}
        </a>
      </div>
    </div>
  );
};

export default DepositRateBlock;