// src/components/Tax.js
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import './Tax.css';

// Функция для динамического импорта налоговых льгот
const importTaxes = async () => {
  const taxes = [];
  const context = require.context('./tax_break', false, /\.js$/);

  context.keys().forEach((key) => {
    const module = context(key);
    const taxInfo = module.taxInfo;

    if (taxInfo && taxInfo.slug) {
      taxes.push({
        id: taxInfo.id,
        title: taxInfo.title || "Без заголовка",
        date: taxInfo.date || "Дата не указана",
        slug: taxInfo.slug
      });
    } else {
      console.error(`Налоговая льгота ${key} не содержит ожидаемых свойств или slug`);
    }
  });

  // Сортировка налоговых льгот по id (чем выше id, тем выше льгота в списке)
  return taxes.sort((a, b) => b.id - a.id);
};

const Tax = () => {
  const [taxes, setTaxes] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadTaxes = async () => {
      try {
        const loadedTaxes = await importTaxes();
        setTaxes(loadedTaxes);
      } catch (error) {
        console.error("Ошибка при загрузке налоговых льгот:", error);
      } finally {
        setLoading(false);
      }
    };

    loadTaxes();
  }, []);

  if (loading) {
    return <div>Загрузка...</div>;
  }

  return (
    <>
    <Helmet>
        <title>Налоговые льготы - 52week.ru, советы и статьи о налоговых льготах и социальных выплатах</title>
        <meta name="description" content="Читайте интересные советы и статьи о налоговых льготах и социальных выплатах" />
        <meta property="og:title" content="Налоговые льготы - 52week.ru, советы и статьи о налоговых льготах и социальных выплатах" />
        <meta property="og:description" content="Читайте интересные советы и статьи о налоговых льготах и социальных выплатах" />
        <meta property="og:type" content="52week.ru" />
        <meta property="og:url" content="https://52week.ru/tax" />
    </Helmet>
    <div className="tax-content">
      <div className="tax-container">
        <div className="tax">
          {taxes.map((tax) => (
            <div key={tax.id} className="tax-preview">
              <h2 className="tax-title">
                <Link to={`/tax/${tax.slug}`}>
                  {tax.title}
                </Link>
              </h2>
              <p className="tax-date">{tax.date}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
    </>
  );
};

export default Tax;