import React from 'react';
import Feature from '../Feature';
import featureImage from './img/16438c84ac9711efa440429ae8867ffa_1.jpeg'; // Правильный путь к изображению
import { generateSlug } from '../../utils/slugGenerator';
import { Helmet } from 'react-helmet';

const Feature1 = () => {
  return (
    <Feature
      title={featureInfo.title}
      date={featureInfo.date}
      image={featureImage} // Используйте импортированное изображение
      content={
        <>
        <Helmet>
        <title>{featureInfo.title}</title>
        <meta name="description" content="В 2025 году мы представим личный кабинет с функцией сохранения и трекинга финансовых целей..." />
      </Helmet>
          <p>В 2025 году мы представим личный кабинет с функцией сохранения и трекинга финансовых целей. Наш сервис станет персональным навигатором в мире личных финансов.</p>
          <p><strong>Ключевые преимущества</strong></p>
          <p>1. Стратегическое планирование</p>
          <ul>
              <li>Постановка четких финансовых целей</li>
              <li>Расчет оптимальной стратегии достижения</li>
              <li>Персонализированный подход к каждому пользователю</li>
          </ul>
          <p>2. Инструменты достижения целей</p>
          <p>Мы предложим комплексные решения:</p>
          <ul>
              <li>Накопительные счета</li>
              <li>Вклады с оптимальной доходностью</li>
              <li>Инвестиции в облигации</li>
              <li>Автоматизированные savings-планы</li>
          </ul>
          <p><strong>Технологические особенности</strong></p>
          <ul>
              <li>Интуитивный интерфейс</li>
              <li>Прогнозирование финансовых результатов</li>
              <li>Еженедельная аналитика прогресса</li>
              <li>Адаптивность под индивидуальные потребности клиента</li>
          </ul>
          <p><strong>Миссия проекта</strong></p>
          <p>Трансформировать финансовое планирование, сделав его простым, прозрачным и эффективным для каждого пользователя.</p>
          <p>🚀 Запуск: 2025 год</p>
        </>
      }
    />
  );
};

// Заголовок статьи
const title = "Личный кабинет и сохранение целей";

// Экспортируем объект с информацией о статье
export const featureInfo = {
  id: 1,
  title: title,
  date: "26 ноября, 2024",
  slug: generateSlug(title) // Генерация slug из title
};

export default Feature1;