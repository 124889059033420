import React, { useState } from 'react';
import { Link } from 'react-router-dom'; // Импортируем Link из react-router-dom
import './Header.css'; // Импортируем стили для Header
import logo from '../logo.svg'; // Импортируйте логотип

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <div className="header">
      <div className="logo-container">
        {/* Оборачиваем логотип в Link для перенаправления на главную страницу */}
        <Link to="/">
          <img src={logo} alt="Логотип" className="logo" />
        </Link>
      </div>
      <div className={`menu ${isMenuOpen ? 'open' : ''}`}>
        <Link to="/pay" className="menu-link">Выплаты от государства</Link>
        <Link to="/tax" className="menu-link">Налоговые льготы</Link>
        <Link to="/blog" className="menu-link">Блог</Link>
        {/* {isMenuOpen && <Link to="/#" className="menu-link">Войти</Link>} Условное отображение login-link */}
      </div>
      {/* <Link to="/#" className="login-link">Войти</Link> */}
      <div className="burger" onClick={toggleMenu}>
        {isMenuOpen ? '✖' : '☰'}
      </div>
    </div>
  );
};

export default Header;