/* global ym */

import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation, matchPath } from 'react-router-dom';
import { AuthProvider } from './context/AuthContext';
import Header from './components/header';
import MiniHeader from './components/miniheader';
import Footer from './components/footer';
import Body from './components/body';
import Sidebar from './components/sidebar';
import Page1 from './components/1';
import Page2 from './components/2';
import Page3 from './components/3';
import Page4 from './components/4';
import Page5 from './components/5';
import Blog from './components/Blog';
import Tax from './components/Tax';
import Pay from './components/Pay';
import ArticlePage from './components/ArticlePage';
import FeaturePage from './components/FeaturePage';
import TaxPage from './components/TaxPage';
import PayGovPage from './components/PayGovPage';
import LoginPage from './components/LoginPage';
import AdminPage from './components/AdminPage';
import ProtectedRoute from './components/ProtectedRoute';

import AdminPage2 from './components/AdminPage2';//удалить

import './styles.css';
import './log.js';

const App = () => {
  const location = useLocation();
  const noHeaderPaths = ['/1', '/2', '/3', '/4', '/5', '/blog', '/article/:slug', '/tax', '/tax/:slug', '/pay', '/pay/:slug', '/feature/:slug', '/login', '/admin', '/admin2'];
  const shouldHideMiniHeader = noHeaderPaths.some(path =>
    matchPath({ path, end: true }, location.pathname)
  );

  useEffect(() => {
    trackPageView(location.pathname);
  }, [location]);

  return (
    <AuthProvider>
    <div className="app">
      <Header />

      {!shouldHideMiniHeader && <MiniHeader />}
      <Routes>
          <Route path="/article/:slug" element={<ArticlePage />} />
          <Route path="/tax/:slug" element={<TaxPage />} />
          <Route path="/pay/:slug" element={<PayGovPage />} />
          <Route path="/feature/:slug" element={<FeaturePage />} />
      </Routes>
      <div className="main-content">
        <Routes>
          <Route path="/login" element={<LoginPage />} />
          <Route path="/admin" element={<ProtectedRoute><AdminPage /></ProtectedRoute>} />

          <Route path="/admin2" element={<AdminPage2 />} />

          <Route path="/1" element={<Page1 />} />
          <Route path="/2" element={<Page2 />} />
          <Route path="/3" element={<Page3 />} />
          <Route path="/4" element={<Page4 />} />
          <Route path="/5" element={<Page5 />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/tax" element={<Tax />} />
          <Route path="/pay" element={<Pay />} />
        </Routes>
        {!shouldHideMiniHeader && (
          <>
            <Body />
            <Sidebar />
          </>
        )}
      </div>
      <Footer />
    </div>
    </AuthProvider>
  );
};

function trackPageView(url) {
    ym(97837559, 'hit', url || window.location.pathname, {
        params: {
            title: document.title,
            referer: document.referrer
        }
    });
}

export default App;