import React from 'react';
import Block from './block'; // Убедитесь, что путь к компоненту правильный
import DepositRateBlock from './DepositRateBlock';
import CurrencyRates from './CurrencyRates';
import News from './News'; // Импортируем компонент новостей
import FutureProduct from './FutureProduct'; // Импортируем компонент будущее сервиса

const Sidebar = () => {
  return (
    <div className="sidebar">
      <Block number={1}>
        <DepositRateBlock 
          rate1={+2} 
          rate2={25} 
          linkText="МКБ"
          linkUrl="https://mkb.ru/"
        />
      </Block>
      <Block number={2}>
        <CurrencyRates />
      </Block>
      <Block number={3}>
        <News /> {/* Здесь добавляем компонент новостей */}
      </Block>
      <Block number={4} title="Обратная связь и предложения">
        {/* Здесь можно оставить пустым или добавить другие элементы */}
      </Block>
      <Block number={5}>
      <FutureProduct /> {/* Здесь добавляем компонент новостей */}
      </Block>
    </div>
  );
};

export default Sidebar;