import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import './LoginPage.css'; // Импортируем стили

const LoginPage = () => {
  const [credentials, setCredentials] = useState({ username: '', password: '' });
  const { login } = useAuth();
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const success = await login(credentials.username, credentials.password);
    if (success) {
      navigate('/admin'); // Перенаправление после успешного входа
    } else {
      alert('Неверные учетные данные'); // Сообщение об ошибке
    }
  };

  return (
    <div className="login-page-body">
      <h2 className="login-page-title">Вход в панель администратора</h2>
      <form onSubmit={handleSubmit}>
        <div className="login-page-input-group">
          <label className="login-page-label">Имя пользователя:</label>
          <input 
            className="login-page-input"
            type="text" 
            value={credentials.username}
            onChange={(e) => setCredentials({ ...credentials, username: e.target.value })} 
          />
        </div>
        <div className="login-page-input-group">
          <label className="login-page-label">Пароль:</label>
          <input 
            className="login-page-input"
            type="password" 
            value={credentials.password}
            onChange={(e) => setCredentials({ ...credentials, password: e.target.value })} 
          />
        </div>
        <button className="login-page-cta-button" type="submit">Войти</button>
      </form>
    </div>
  );
};

export default LoginPage;
