import React, { useState } from 'react';
import FeedbackPopup from './FeedbackPopup'; // Импортируем компонент попапа
import { logButtonClick } from '../log'; // Импортируем функцию логирования

const Block = ({ number, title, children }) => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const handleFeedbackClick = (event) => {
    event.preventDefault(); // Предотвращаем стандартное поведение
    logButtonClick(event, 'feedback_button'); // Логируем нажатие на кнопку "Обратная связь"
    setIsPopupOpen(true); // Открываем попап
  };

  return (
    <div className={`block block-${number}`}>
      {title && <h2>{title}</h2>}
      {children}
      {title === "Обратная связь и предложения" && (
        <button className="feedback-button" onClick={handleFeedbackClick}>
          Обратная связь
        </button>
      )}
      {isPopupOpen && (
        <div className="overlay">
          <FeedbackPopup onClose={() => setIsPopupOpen(false)} />
        </div>
      )}
    </div>
  );
};

export default Block;