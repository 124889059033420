import React, { useEffect, useState, useRef } from 'react';
import './CurrencyRates.css';

const CurrencyRates = () => {
    const [usdRate, setUsdRate] = useState(null);
    const [eurRate, setEurRate] = useState(null);
    const [btcRate, setBtcRate] = useState(null);
    const [btcInRUB, setBtcInRUB] = useState(null);
    
    const ratesContainerRef = useRef(null);

    const fetchCurrencyRates = async () => {
        try {
            const response = await fetch('https://iss.moex.com/iss/statistics/engines/currency/markets/selt/rates.json?iss.meta=off');
            if (!response.ok) throw new Error('Ошибка сети: ' + response.status);
            const data = await response.json();

            if (data && data.cbrf && data.cbrf.data) {
                const cbrfData = data.cbrf.data[0];
                const usdIndex = data.cbrf.columns.indexOf('CBRF_USD_LAST');
                const eurIndex = data.cbrf.columns.indexOf('CBRF_EUR_LAST');

                if (usdIndex !== -1) setUsdRate(cbrfData[usdIndex]);
                if (eurIndex !== -1) setEurRate(cbrfData[eurIndex]);
            }
        } catch (error) {
            console.error('Ошибка при получении данных:', error);
        }
    };

    const fetchBitcoinPrice = async () => {
        try {
            const response = await fetch('https://api.binance.com/api/v3/ticker/price?symbol=BTCUSDT');
            if (!response.ok) throw new Error('Ошибка сети: ' + response.status);
            const data = await response.json();
            setBtcRate(data.price);

            if (usdRate !== null) {
                const btcInRUBValue = parseFloat(data.price) * parseFloat(usdRate);
                setBtcInRUB(btcInRUBValue);
            }
        } catch (error) {
            console.error('Ошибка при получении курса биткоина:', error);
        }
    };

    useEffect(() => {
        fetchCurrencyRates();
        fetchBitcoinPrice();
    }, []);

    useEffect(() => {
        if (btcRate !== null && usdRate !== null) {
            const btcInRUBValue = parseFloat(btcRate) * parseFloat(usdRate);
            setBtcInRUB(btcInRUBValue);
        }
    }, [btcRate, usdRate]);

    const adjustFontSize = () => {
        let fontSize = 2;
        if (ratesContainerRef.current) {
            ratesContainerRef.current.style.fontSize = `${fontSize}vh`;
            while (ratesContainerRef.current.scrollWidth > ratesContainerRef.current.clientWidth) {
                fontSize -= 0.1;
                ratesContainerRef.current.style.fontSize = `${fontSize}vh`;
                if (fontSize <= 1) break;
            }
        }
    };

    useEffect(() => {
        adjustFontSize();
    }, [usdRate, eurRate, btcInRUB]);

    // Функция для форматирования числа с двумя знаками после запятой
    const formatNumber = (number) => {
        return number !== null ? parseFloat(number).toLocaleString('ru-RU', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '-';
    };

    return (
        <div className="currency-rates" ref={ratesContainerRef}>
            <div className="rate">
                <span>USD: {formatNumber(usdRate)} ₽</span>
            </div>
            <div className="rate">
                <span>EUR: {formatNumber(eurRate)} ₽</span>
            </div>
            <div className="rate">
                <span>BTC: {formatNumber(btcInRUB)} ₽</span>
            </div>
        </div>
    );
};

export default CurrencyRates;