/* global ym */

import React from 'react';
import { Link } from 'react-router-dom'; // Импортируем Link из react-router-dom
import './Body.css'; // Импортируем стили для Body
import motivation from '../motivation.png'; // Импортируйте изображение
import { logButtonClick } from '../log'; // Импортируем функцию логирования

const Body = () => {
  const handleButtonClick = (event) => {
    logButtonClick(event, 'prepare_plan'); // Логируем клик по кнопке
    ym(97837559, 'reachGoal', 'step_0'); // Цель Яндекс.Метрики
  };

  return (
    <div className="body">
      <h1 className="main-title">Новый опыт достижения финансовых целей</h1>
      <h3 className="sub-title">Быстро. Персонально. С любовью.❤️</h3>
      <img src={motivation} alt="Мотивация" className="image" />
      <Link to="/1" className="menu-link cta-button" onClick={handleButtonClick}>
        Подготовить план
      </Link>
    </div>
  );
};

export default Body;